<template>
    <div class="box">
        <div class="box-header">
            <h3 class="box-title">Seznam aut</h3>
        </div>
        <div class="box-body">
            <div class="row">
                <div class="col-lg-4">
                    <div class="input-group input-group-sm" style="width: 100%">
                        <input v-model="filter" type="text" name="table_search" class="form-control" placeholder="Hledat">
                    </div>
                </div>
                <div v-if="!user.cus" class="col-lg-8">
                    <router-link :to="{ name: 'CarAdd' }" class="btn btn-default btn-flat btn-sm pull-right"><i class="fa fa-plus"></i> Přidat auto</router-link>
                </div>
            </div>
            <br>
            <div v-if="allCars.length" class="table-responsive no-padding" style="width: auto; overflow: auto">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th style="width: 36px"></th>
                        <th>Název</th>
                        <th v-if="!user.cus">Zákazník</th>
                        <th v-if="!(user.cus && !user.admin)">Řidič</th>
                        <th>VIN</th>
                        <th>SPZ</th>
                        <th>Kód motoru</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="car in getCars" :key="car.carid">
                            <td><span class="label" :style="`border: solid 1px #EEEFF4;background-color: ${car.color}; color: ${car.color}`">_</span></td>
                            <td style="cursor: pointer" @click="makeCurrentCar(car.carid)"><a>{{car.name}}</a></td>
                            <td style="cursor: pointer" @click="makeCurrentCus(car.customerid)" v-if="!user.cus"><a>{{car.company_name}}</a></td>
                            <td style="cursor: pointer" @click="makeCurrentMnt(car.maintainerid)" v-if="!(user.cus && !user.admin)"><a>{{car.fullname}}</a></td>
                            <td>{{car.vin_code}}</td>
                            <td>{{car.licence_plate}}</td>
                            <td>{{car.engine_code}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else class="callout callout-info">
                <p><i class="icon fa fa-info"></i>Nejsou evidována žádná auta.</p>                          
            </div>
        </div>
        <div v-if="!loaded" class="overlay">
            <i class="fa fa-refresh fa-spin"></i>
        </div>
    </div>
</template>

<script>
const name = 'Cars';

import { mapGetters, mapActions } from "vuex";

export default {
    name,
    data() {
        return {
            filter: '',
            loaded: false
        };
    },
    computed: {
        ...mapGetters(["getAllPages", 'allCars', 'user']),
        getCars() {
            var allCars = this.allCars

            if (!allCars.length)
            {
                return []
            }
            
            // Filtr provedeme na vícero položkách auta -> možnost vyhledávání jak podle jména, tak podle VIN, atd..
            return allCars.filter(item => {
                return (this.$helpers.filterCheck(this.filter, item.name) || this.$helpers.filterCheck(this.filter, item.licence_plate) || this.$helpers.filterCheck(this.filter, item.vin_code));
            })
        }
    }, 
    methods: {
        ...mapActions(["makeActiveTab", "addTab", "fetchCars", 'makeCurrentCar', 'makeCurrentCus', 'makeCurrentMnt']),
    },
    created() {
        this.fetchCars().then(() => {
            this.loaded = true;
        });

        const page = this.getAllPages.filter(page => page.name == name)[0]   
        this.addTab(page);
        this.makeActiveTab(page);
    }
}
</script>